import { createSlice } from '@reduxjs/toolkit';
import type { Product } from '../../types/product.ts';
import { Cart } from '../../services/cart.ts';

const initialState: Cart = {
    id: '',
    totalCartItems: 0,
    cartSubTotal: 0,
    orderTotal: 0,
    shippingSubTotal: 0,
    savingsTotal : 0,
    taxTotal: 0,
    products: [],
    paymentAmounts: {},
    paymentInstruments: [],
    adjustedTax: 0,
    adjustedTotal: 0,
    shippingMethod: { id:'', name:'', shippingTax: 0 },
    addedToCart: {}
};
export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setCart: (state, action) => {
            return {
                ...action.payload,
                totalCartItems: action.payload?.products?.map((product: Product) => product.quantity).reduce((a: number, b: number) => a + b, 0) ?? 0
            };
        },
        reset: (state) => {
            return initialState
        }
    }
})

export const { setCart } = cartSlice.actions;
export const cartActions = cartSlice.actions;
export default cartSlice.reducer;
