import { useRef, useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useGetContentAssetQuery } from '../../../services/content';
import { useLocation } from 'react-router-dom';

/**
 * @description This component is responsible for loading the Criteo OneTag script 
 *              and triggering the corresponding events on different pages.
 * @returns {null} This component does not render anything.
 */
const CriteoOneTag = () => {
    const { pathname } = useLocation();
    const scriptLoaded = useRef(false);
    const { data: criteoContentAsset, isFetching: isFetchingCriteoContentAsset } = useGetContentAssetQuery('criteo-partner-id');
    const EmailAddress = useSelector(state => state.userReducer.user.EmailAddress);
    const siteName = useSelector(state => state.commonReducer.siteName);
    const plpState = useSelector((state) => state.plpReducer);
    const productActivity = useSelector(state => state.pdpReducer);
    const currentCart = useSelector((state) => state.cartReducer);
    const orderState = useSelector((state) => state.orderReducer);
    var criteoItems = [];
    const [criteoPartnerId, setCriteoPartnerId] = useState(null);
    const [hashEmailAddress, setHashEmailAddress] = useState(null);
    var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";

    // hash method
    async function sha256(email) {
        const msgBuffer = new TextEncoder('utf-8').encode(email);
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
        return hashHex;
    }

    function format(email) {
        return email.toLowerCase().trim();
    }

    // hash Email Address
    useEffect(() => {
        if (typeof EmailAddress === 'string') {
            var hashPromise = sha256(format(EmailAddress));
            hashPromise.then((res) => {
                setHashEmailAddress(res);
            });
        }
    }, [EmailAddress]);

    // get Criteo partner id
    useEffect(() => {
        if (criteoContentAsset && !isFetchingCriteoContentAsset) {
            setCriteoPartnerId(criteoContentAsset?.ContentValue);
        }
    }, [criteoContentAsset, isFetchingCriteoContentAsset]);

    // load Criteo script
    useEffect(() => {
        if (!scriptLoaded.current && criteoPartnerId) {
            const scriptId = 'criteo-script';
            if (!document.getElementById(scriptId)) {
                var s = document.createElement('script');
                s.id = scriptId;
                s.async = true;
                s.src = 'https://static.criteo.net/js/ld/ld.js?a=' + criteoPartnerId;
                var t = document.getElementsByTagName('script')[0];
                t.parentNode.insertBefore(s,t);
                scriptLoaded.current = true;
            }
        }
    }, [criteoPartnerId]);

    // viewHome
    useEffect(() => {
        if ((pathname === '/' || pathname === '/en/') && scriptLoaded?.current && hashEmailAddress) {
            window.criteo_q = window.criteo_q || [];
            window.criteo_q.push(
                { event: "setAccount", account: criteoPartnerId},
                { event: "setEmail", email: hashEmailAddress, hash_method: "sha256" },
                { event: "setData", ui_location: siteName},
                { event: "setSiteType", type: deviceType},
                { event: "viewHome", tms: "custom-guide" }
            );
        }
    }, [pathname, scriptLoaded?.current, hashEmailAddress]);

    // viewList
    useEffect(() => {
        if (plpState.products?.length > 0 && scriptLoaded?.current && hashEmailAddress) {
            {plpState?.products?.slice(0, 3).map((item) => (
                criteoItems.push(item.ProductId)
            ))};
            window.criteo_q = window.criteo_q || [];
            window.criteo_q.push(
                { event: "setAccount", account: criteoPartnerId},
                { event: "setEmail", email: hashEmailAddress, hash_method: "sha256" },
                { event: "setData", ui_location: siteName},
                { event: "setSiteType", type: deviceType},
                { event: "viewList", item: criteoItems, tms: "custom-guide" }
            );
        }
    }, [plpState.products?.length, scriptLoaded?.current, hashEmailAddress]);

    // viewItem
    useEffect(() => {
        if (productActivity?.productId && scriptLoaded?.current && hashEmailAddress && pathname.indexOf('cart') < 0) {
            window.criteo_q = window.criteo_q || [];
            window.criteo_q.push(
                { event: "setAccount", account: criteoPartnerId},
                { event: "setEmail", email: hashEmailAddress, hash_method: "sha256" },
                { event: "setData", ui_location: siteName},
                { event: "setSiteType", type: deviceType},
                { event: "viewItem", item: productActivity.productId, tms: "custom-guide" }
            );
        }
    }, [productActivity?.productId, scriptLoaded?.current, hashEmailAddress]);

    // addToCart
    useEffect(() => {
        if (Object.keys(currentCart?.addedToCart).length !== 0 && scriptLoaded?.current && hashEmailAddress) {
            window.criteo_q = window.criteo_q || [];
            window.criteo_q.push(
                { event: "setAccount", account: criteoPartnerId},
                { event: "setEmail", email: hashEmailAddress, hash_method: "sha256" },
                { event: "setData", ui_location: siteName},
                { event: "setSiteType", type: deviceType},
                { event: "addToCart", item: [currentCart.addedToCart], tms: "custom-guide" }
            );
        }
    }, [currentCart?.addedToCart, scriptLoaded?.current, hashEmailAddress]);

    // viewBasket
    useEffect(() => {
        if (pathname.indexOf('cart') > -1 && scriptLoaded?.current && hashEmailAddress) {
            {currentCart?.products?.map((item) => {
                var cartItemObject = {
                    id: item.productId,
                    price: item.productPrice,
                    quantity: item.quantity
                };

                criteoItems.push(cartItemObject)
            })};

            window.criteo_q = window.criteo_q || [];
            window.criteo_q.push(
                { event: "setAccount", account: criteoPartnerId},
                { event: "setEmail", email: hashEmailAddress, hash_method: "sha256" },
                { event: "setData", ui_location: siteName},
                { event: "setSiteType", type: deviceType},
                { event: "viewBasket", item: criteoItems, tms: "custom-guide" }
            );
        }
    }, [pathname, scriptLoaded?.current, hashEmailAddress]);

    // trackTransaction
    useEffect(() => {
        if (pathname.indexOf('order-confirmation') > -1 && scriptLoaded?.current && hashEmailAddress) {
            var transactionID = orderState?.orderNumber ? orderState.orderNumber : 'OFFLINEORDER';
            {orderState?.cart?.products?.map((item) => {
                var cartItemObject = {
                    id: item.productId,
                    price: item.productPrice,
                    quantity: item.quantity
                };

                criteoItems.push(cartItemObject)
            })};

            window.criteo_q = window.criteo_q || [];
            window.criteo_q.push(
                { event: "setAccount", account: criteoPartnerId},
                { event: "setEmail", email: hashEmailAddress, hash_method: "sha256" },
                { event: "setData", ui_location: siteName},
                { event: "setSiteType", type: deviceType},
                { event: "trackTransaction", id: transactionID, item: criteoItems, tms: "custom-guide" }
            );
        }
    }, [pathname, scriptLoaded?.current, hashEmailAddress]);

    return null;
}

export default CriteoOneTag;
