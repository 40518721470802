import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, unstable_useBlocker as useBlocker } from 'react-router-dom';
import { Card, CardBody, CardHeader, Collapse, Tooltip } from 'reactstrap';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import { useSelector, useDispatch } from 'react-redux';
import { showErrorMsg } from '../../../helpers/ValidationHelper';
import rootAction from '../../../stateManagment/actions/rootAction';
import { formatCurrency, makePriceRoundToTwoPlaces } from '../../../helpers/ConversionHelper';
import { formatPhoneNumber } from 'react-phone-number-input';
import ContentLoader from 'react-content-loader';
import { Fragment } from 'react';
import { GetDefaultCurrencySymbol, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';
import Seo from '../../components/shared/Seo';
import { useGetCartDataQuery } from '../../../services/cart';
import { usePlaceOrderMutation } from '../../../services/checkout';
import { Feedback } from '../../components/feedback/Feedback';
import LoadingScreen from '../../components/shared/LoadingScreen';
import { api } from '../../../services/api';
import { PAYMENT_INSTRUMENTS } from '../../../helpers/Constants';
import { ToggleContext } from '../../../ToggleProvider';
import { OrderSummary } from './OrderSummary';

const ShippingBagSummary = ({ cart }) => {
    const [isOpenBagSummary, setIsOpenBagSummary] = useState(false);
    const { isToggled, setIsToggled } = useContext(ToggleContext);
    const toggleBagSummary = () => {
        setIsOpenBagSummary(!isOpenBagSummary);
    };
    const toggleIcon = (visible) => {
        return visible ? <span className='icon icon-arrowUp' /> : <span className='icon icon-arrowDown' />;
    };
    const LocalizationLabelsArray = [];
    return (
        <Card color="light" className="card-sidebar products-summary">
            <CardHeader tag="h5" role="button" onClick={toggleBagSummary}>
                {LocalizationLabelsArray.length > 0 ?
                    replaceLoclizationLabel(LocalizationLabelsArray, "Shopping Bag", "lbl_check_shoppingbag")
                    :
                    "Shopping Bag"
                }
                &nbsp;({cart?.totalCartItems})
                {toggleIcon(isOpenBagSummary)}
            </CardHeader>
            <Collapse isOpen={isOpenBagSummary}>
                <CardBody>
                    <ul className="list-group list-group-flush">
                        {cart?.products?.map((data, idx) => (
                            <li className="list-group-item" key={idx}>
                                <div className='product-line-item'>
                                    <div className='product-image'>
                                        <img src={data.images[0]?.url.replace('?sw=100&sh=150&sm=fit', '?sw=130&sh=193&sm=fit')} alt={data.productName} className='img-fluid' />
                                    </div>
                                    <div className='product-info'>
                                        <div className='line-item-title'>
                                            <p>
                                                <strong>{data.productName}</strong>
                                            </p>
                                            <p>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Style", "lbl_check_style")
                                                    :
                                                    "Style"
                                                }
                                                &nbsp;#: {data.productId}
                                            </p>
                                        </div>

                                        <div className="line-item-summary">

                                            {data.colorName &&
                                                <div className={isToggled ? 'attribute-item justify-content-between' : 'attribute-item '}>
                                                    <span className='label'>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Color", "lbl_check_color")
                                                            :
                                                            "Color"
                                                        }
                                                        :
                                                    </span>
                                                    <span>{data.ColorLongName}</span>
                                                </div>}

                                            {data.sizeShortName &&
                                                <div className={isToggled ? 'attribute-item justify-content-between' : 'attribute-item '}>
                                                    <span className='label'>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Size", "lbl_check_size")
                                                            :
                                                            "Size"
                                                        }
                                                        :
                                                    </span>
                                                    <span>{data.sizeShortName.replace('_', '/')}</span>
                                                </div>}
                                            
                                            {isToggled &&
                                                <div className="attribute-item justify-content-between">
                                                    <span className='label'>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Price", "lbl_check_qty")
                                                            :
                                                            "Qty"
                                                        }
                                                        :
                                                    </span>
                                                    <span>
                                                        {data.quantity}
                                                    </span>
                                                </div>
                                            }

                                            {data.SelectedEmployee ?
                                                <div className="attribute-item">
                                                    <span className='label'>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Employee", "lbl_check_employee")
                                                            :
                                                            "Employee"
                                                        }
                                                    </span>
                                                    <span>{data.SelectedEmployee.replace(',', ' ')}</span>
                                                </div>
                                                :
                                                <></>
                                            }
                                        </div>

                                        <div className="line-item-summary">
                                            {!isToggled &&
                                                <div className="summary-item">
                                                    <span className='label'>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Price", "lbl_check_price")
                                                            :
                                                            "Price"
                                                        }
                                                    </span>
                                                    <span>
                                                        {formatCurrency((data.discountedPrice / data.quantity) - data.embroideryPrice)}
                                                    </span>
                                                </div>
                                            }

                                            {!isToggled &&
                                                <div className="summary-item">
                                                    <span className='label'>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Price", "lbl_check_qty")
                                                            :
                                                            "Qty"
                                                        }
                                                    </span>
                                                    <span>
                                                        {data.quantity}
                                                    </span>
                                                </div>
                                            }

                                            {!isToggled &&
                                                <div className="summary-item">
                                                    <span className='label'>
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Price", "lbl_check_total")
                                                            :
                                                            "Total"
                                                        }
                                                    </span>
                                                    <span>
                                                        {formatCurrency(data.productPrice + (data.embroideryPrice * data.quantity))}
                                                    </span>
                                                </div>
                                            }

                                            {data.embroidery?.length > 0 ?
                                                <>
                                                    <hr></hr>
                                                    <div className="summary-item">
                                                        <span className='label'>
                                                            {LocalizationLabelsArray.length > 0 ?
                                                                replaceLoclizationLabel(LocalizationLabelsArray, "Embroidery", "lbl_check_embroidery")
                                                                :
                                                                "Embroidery"
                                                            }
                                                        </span>
                                                        {!isToggled &&
                                                            <span>
                                                                {formatCurrency(data.embroideryPrice)}
                                                            </span>
                                                        }
                                                    </div>
                                                </>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </CardBody>
            </Collapse>
        </Card>
    )
}

const AddressSummary = ({ shippingMethod, paymentMethods, total, address, email, type, title, subTitle, stepNumber }) => {
    const LocalizationLabelsArray = [];
    const checkoutActivity = useSelector(state => state.checkout);
    const { isToggled } = useContext(ToggleContext);

    return (
        <div className={`card mb-5 card-summary ${type === 'shipping' ? 'shipping-summary' : 'billing-summary'}`}>
            {(stepNumber || title) &&
                <h5 className="card-header d-flex align-items-center">
                    <div className="number-circle">{stepNumber}</div>
                    {LocalizationLabelsArray.length > 0 ?
                        replaceLoclizationLabel(LocalizationLabelsArray, title, "lbl_check_" + type)
                        :
                        title
                    }
                </h5>}
            <div className="card-body">
                <div className='row'>
                    {type !== 'po' && <div className='col-md-6 col-lg-4'>
                        <div className="customer-summary">
                            <p className='label mb-0 mb-md-2'>
                                {LocalizationLabelsArray.length > 0 ?
                                    replaceLoclizationLabel(LocalizationLabelsArray, subTitle, "lbl_check_shippingaddress")
                                    :
                                    subTitle
                                }
                            </p>
                            {address ?
                                <>
                                    <p>{address.fullName}</p>
                                    <p>{address.address1}</p>
                                    {address.address2 ?
                                        <p>{address.address2}</p>
                                        :
                                        null
                                    }
                                    <p>{address.city}, {address.stateCode} {address.postalCode}</p>
                                    <p>{address.countryCode === 'US' || address.countryCode === 'USA' ? 'United States' : ''}</p>
                                </>
                                :
                                <ContentLoader
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                    viewBox="0 0 280 105"
                                    height={112}
                                >
                                    <rect x="0" y="0" width="125" height="22" />
                                    <rect x="0" y="28" width="125" height="22" />
                                    <rect x="0" y="56" width="125" height="22" />
                                    <rect x="0" y="84" width="125" height="22" />
                                </ContentLoader>
                            }
                        </div>
                    </div>}
                    {type === 'shipping' &&
                        <>
                            <div className='col-md-6 col-lg-4 mt-4 mt-md-0'>
                                <div className="customer-summary">
                                    <p className='label mb-0 mb-md-2'>
                                        {LocalizationLabelsArray.length > 0 ?
                                            replaceLoclizationLabel(LocalizationLabelsArray, "Order contact information", "lbl_check_ordercontactinformation")
                                            :
                                            "Order contact information"
                                        }
                                    </p>
                                    {address.phone || email ?
                                        <>
                                            {email ?
                                                <p>{email}</p>
                                                :
                                                null
                                            }
                                            {address.phone ?
                                                <p>{formatPhoneNumber('+1' + address.phone)}</p>
                                                :
                                                null
                                            }
                                        </>
                                        :
                                        <ContentLoader
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#ecebeb"
                                            viewBox="0 0 280 50"
                                            height={32}
                                        >
                                            <rect x="0" y="0" width="200" height="100" />
                                        </ContentLoader>
                                    }
                                </div>
                            </div>

                            <div className='col-md-6 col-lg-4 mt-4 mt-lg-0'>
                                <div className="customer-summary">
                                    <p className='label mb-0 mb-md-2'>
                                        {LocalizationLabelsArray.length > 0 ?
                                            replaceLoclizationLabel(LocalizationLabelsArray, "Shipping Method", "lbl_check_shippingmethod")
                                            :
                                            "Shipping Method"
                                        }
                                    </p>
                                    {shippingMethod ?
                                        <p>{shippingMethod.name}{!isToggled ? <>: <span className="font-weight-bold">{shippingMethod.price > 0 ? `${GetDefaultCurrencySymbol()}${makePriceRoundToTwoPlaces(shippingMethod.price)}` : 'FREE'}</span></> : <></>}</p>
                                        :
                                        <ContentLoader
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#ecebeb"
                                            viewBox="0 0 280 50"
                                            height={32}
                                        >
                                            <rect x="0" y="0" width="200" height="100" />
                                        </ContentLoader>
                                    }
                                </div>
                            </div>
                        </>
                    }
                    {type === 'po' &&
                        <div className='col-md-6 col-lg-8 mt-4 mt-md-0'>
                            <p>{checkoutActivity?.customerPurchaseOrder}</p>
                        </div>
                    }

                    {type === 'billing' &&
                        <>
                            <div className='col-md-6 col-lg-8 mt-4 mt-md-0'>
                                <div className="customer-summary">
                                    <p className='label mb-0 mb-md-2'>
                                        {LocalizationLabelsArray.length > 0 ?
                                            replaceLoclizationLabel(LocalizationLabelsArray, "Payment Method", "lbl_check_paymentmethod")
                                            :
                                            "Payment Method"
                                        }
                                    </p>
                                    {paymentMethods ?
                                        paymentMethods.filter(pm => pm.amount > 0).map((paymentMethod) => {
                                            if (paymentMethod.paymentMethodId !== 'CREDIT_CARD') {
                                                return (
                                                    <React.Fragment key={paymentMethod.paymentMethodId}>
                                                        <p className='mb-2'>
                                                            {isToggled ? "Allotment" : PAYMENT_INSTRUMENTS[paymentMethod.paymentMethodId]}{!isToggled ? <>: <span className="font-weight-bold">{formatCurrency(paymentMethod.amount)}</span></> : <></>}
                                                            {paymentMethod.paymentMethodId === 'UAB_PAYROLL_DEDUCTION' ? <span className='d-block'>Max Deductions: <span className="font-weight-bold">{checkoutActivity?.payrollMaxDeductions}</span></span> : null}
                                                            {paymentMethod.paymentMethodId === 'GIFT_CERTIFICATE' ? <span className='d-block'>GC/eGC: <span className="font-weight-bold">{paymentMethod.gcNumber}</span></span> : null}
                                                        </p>
                                                    </React.Fragment>
                                                )
                                            }
                                            return (
                                                <React.Fragment key={paymentMethod.paymentMethodId}>
                                                    <p className='mb-2'>
                                                        {isToggled ? "Allotment" : PAYMENT_INSTRUMENTS[paymentMethod.paymentMethodId]}: <span className="font-weight-bold">{formatCurrency(paymentMethod.amount)}</span>
                                                        <span className="d-block">************{checkoutActivity?.creditCard.last4} Exp. {checkoutActivity?.creditCard.expMonth}/{checkoutActivity?.creditCard.expYear}</span>
                                                    </p>
                                                </React.Fragment>
                                            )
                                        })
                                        :
                                        <ContentLoader
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#ecebeb"
                                            viewBox="0 0 280 50"
                                            height={32}
                                        >
                                            <rect x="0" y="0" width="200" height="100" />
                                        </ContentLoader>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div >
    )
}

const OrderReview = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isSubmitOrderEnabled } = useSelector(state => state.commonReducer);
    const [placeOrder, { isLoading: isPlacingOrder }] = usePlaceOrderMutation();
    useGetCartDataQuery(null);
    const checkoutActivity = useSelector((state) => state.checkout);
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

    const loginUser = useSelector(state => state.userReducer.user);
    const currentCart = useSelector((state) => state.cartReducer);
    const [blockNavigation, setBlockNavigation] = useState(true);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
    
    const { selectedPaymentMethod } = useSelector((state) => state.checkout);

    const adjustedTax = (currentCart.paymentAmounts[selectedPaymentMethod] ?? currentCart.orderTotal) - currentCart.shippingSubTotal - currentCart.cartSubTotal;
    const adjustedTotal = currentCart.paymentAmounts[selectedPaymentMethod] ?? currentCart.orderTotal;

    const { isToggled, setIsToggled } = useContext(ToggleContext);

    const [feedback, setFeedback] = useState({
        message: '',
        type: '',
        open: false
    });

    // Modals
    const toggleFeedback = () => {
        setFeedback({
            ...feedback,
            open: !feedback.open
        });
    }

    useEffect(() => {
        if (checkoutActivity?.isInOrderReview === false) {
            navigate('/checkout');
        }
    }, [checkoutActivity]);

    const handleCheckoutOnSubmit = async (e) => {
        try {
            e.preventDefault();
            setBlockNavigation(false);
            await placeOrder({
                billingAddress: checkoutActivity.billingAddress,
                shippingAddress: checkoutActivity.shippingAddress,
                customerPurchaseOrder: checkoutActivity.customerPurchaseOrder
            }).unwrap()
                .then((response) => {
                    if (response.WebError) {
                        dispatch(rootAction.userAction.rotateCart(response.uacNextBasketId));
                        setFeedback({
                            message: response.WebErrorString,
                            type: 'error',
                            open: true
                        });
                        showErrorMsg(response.WebErrorString);
                        setBlockNavigation(true);
                        navigate('/checkout?WebError=' + encodeURIComponent(response.WebErrorString));
                    } else if (response.fault && response.fault.message) {
                        if (response.uacNextBasketId)
                            dispatch(rootAction.userAction.rotateCart(response.uacNextBasketId));
                        setFeedback({
                            message: response.fault.message,
                            type: 'error',
                            open: true
                        });
                        showErrorMsg(response.fault.message);
                        setBlockNavigation(true);
                        navigate('/checkout?WebError=' + encodeURIComponent(response.fault.message));
                    } else {
                        dispatch(rootAction.orderActions.setOrderConfirmation({
                            cart: currentCart,
                            billingAddress: checkoutActivity.billingAddress,
                            shippingAddress: checkoutActivity.shippingAddress,
                            paymentMethods: checkoutActivity.paymentMethods,
                            shippingMethod: checkoutActivity.shippingMethod,
                            orderNumber: response.c_axOrderNumber,
                            sfccOrderNo: response.order_no,
                            customerPurchaseOrder: response.c_customerPONumberMessage
                        }));
                        dispatch(rootAction.userAction.rotateCart(response.uacNextBasketId));
                        dispatch(rootAction.checkoutActions.reset({ force: true }));
                        dispatch(rootAction.cartActions.reset());
                        dispatch(api.util.invalidateTags(['Cart', 'ShippingMethods', 'PaymentMethods', 'CustomerAddresses']));

                        navigate('/order-confirmation');
                    }
                }).catch((err) => {
                    setFeedback({
                        message: err.data.message,
                        type: 'error',
                        open: true
                    });
                    showErrorMsg(err.data.message);
                    setBlockNavigation(true);
                });
        } catch (err) {
            showErrorMsg("An error occured. Please try again!");
        }
    }

    useEffect(() => {
        // declare the data fetching function
        const dataOperationFunc = async () => {
            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["Checkout"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                setLocalizationLabelsArray(arryRespLocalization);
            }
        }
        // call the function
        dataOperationFunc().catch(console.error);
    }, [])

    return (
        <>
            <Seo
                title="Checkout"
                description="Checkout"
                keywords="Checkout"
            />

            <SiteBreadcrumb title="Checkout" />
            <Feedback open={feedback.open} message={feedback.message} type={feedback.type} toggle={toggleFeedback} />
            <LoadingScreen loading={isPlacingOrder} />

            <section className="checkout-page pb-8 pb-md-9" id="checkout">
                <div className="container-fluid">
                    <div className="section-title pb-3">
                        <h1 className="mb-0 mt-md-n2">
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Review & Place Order", "lbl_check_reviewandplaceorder")
                                :
                                "Review & Place Order"
                            }
                        </h1>
                    </div>

                    <div className="row">
                        <div className="col-md-8">
                            <AddressSummary
                                shippingMethod={checkoutActivity?.shippingMethod}
                                address={checkoutActivity?.shippingAddress || {}}
                                email={loginUser.EmailAddress}
                                type='shipping'
                                title='Shipping'
                                subTitle='Shipping Address'
                                stepNumber={1}
                            />

                            {checkoutActivity?.customerPurchaseOrder?.length > 0 && <AddressSummary
                                paymentMethods={[]}
                                type='po'
                                title='Purchase Order #'
                                subTitle=''
                                stepNumber={2}
                            />}

                            <AddressSummary
                                paymentMethods={currentCart?.paymentInstruments}
                                total={currentCart.orderTotal}
                                address={checkoutActivity?.billingAddress || {}}
                                email={loginUser.EmailAddress}
                                type='billing'
                                title='Billing'
                                subTitle='Billing Address'
                                stepNumber={checkoutActivity?.customerPurchaseOrder?.length > 0 ? 3 : 2}
                            />

                            <div className='row justify-content-end'>
                                <div className="col-12 text-right">
                                    {!isToggled && <>
                                        <p>
                                            Please note the final tax will be calculated when the order is placed and may alter the total.
                                            <span id="tooltip-tax-info" className="icon icon-tooltip d-inline-block ml-2" role="button" tabIndex="0"></span>
                                        </p>
                                        <Tooltip
                                            isOpen={tooltipOpen}
                                            target="tooltip-tax-info"
                                            toggle={toggleTooltip}
                                            innerClassName="text-left"
                                        >
                                            {LocalizationLabelsArray.length > 0 ?
                                                replaceLoclizationLabel(LocalizationLabelsArray, "The sales tax is currently estimated.", "lbl_check_tooltiptax")
                                                :
                                                "The sales tax is currently estimated."
                                            }
                                        </Tooltip>
                                    </>
                                    }
                                </div>
                                <div className='col-12 col-md-6 col-xl-5 pb-4 pb-md-0 place-order pl-4 pr-4'>
                                    <button type='submit' onClick={handleCheckoutOnSubmit} className='btn btn-primary btn-block' id="lbl_check_placeorder" disabled={!isSubmitOrderEnabled}>
                                        {LocalizationLabelsArray.length > 0 ?
                                            replaceLoclizationLabel(LocalizationLabelsArray, "Place Order", "lbl_check_placeorder")
                                            :
                                            "Place Order"
                                        }
                                        {!isToggled ? <>&nbsp;|&nbsp;
                                        {GetDefaultCurrencySymbol() + makePriceRoundToTwoPlaces(adjustedTotal)}</> : <></>}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            {!isToggled && 
                                <OrderSummary
                                    id={`summary-${currentCart?.adjustedTotal}`}
                                    cart={currentCart}
                                    adjustedTax={adjustedTax}
                                    adjustedTotal={adjustedTotal}
                            />}

                            <ShippingBagSummary
                                cart={currentCart}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

function Prompt(props) {
    const block = props.when
    const [showPrompt, setShowPrompt] = useState(false)
    useEffect(() => {
        if (block) {
            setShowPrompt(true)
        }
        return () => {
            setShowPrompt(false)
        }
    }, [block])

    useBlocker(() => {
        if (showPrompt) {
            return !window.confirm(props.message)
        }
        return false
    })

    return (
        <div key={showPrompt} />
    )
}

export default OrderReview;
